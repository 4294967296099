nav {
  box-shadow: 0 0 18px rgb(0 0 0 / 20%);
  background: white;
}

.load-more-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  border: none;
}

/* .nav-link {
  color: #3c3c3c !important;
} */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #08019f;
  color: white;
  padding: 10px 0px;
}

.main {
  margin-top: 100px;
  padding: 10px;
  margin-bottom: 10px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.custom-file-label::after {
  background-color: #08019f !important;
  border-color: #08019f !important;
  color: white;
  border-radius: 0px !important;
}

.custom-file-label {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  margin: 0 auto;
}

.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}

.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.pagination-container .pagination-item.selected {
  background-color: #00d7b9;
}

.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
  Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}

.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-container .pagination-item.disabled {
  pointer-events: none;
}

.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.mars-bg {
  background-color: #08019f;
  color: #ffffff;
}

#login {
  margin: 0 auto;
}

.zip-image {
  padding-top: 15px;
  height: 180px;
  object-fit: scale-down;
}

.pdf-image {
  height: 180px;
  margin-top: 15px;
}

#loading-bar-spinner.spinner {
  /* left: 35%; */
  /* margin-left: -100px; */
  /* top: 50%; */
  /* margin-top: -150px; */
  position: absolute;
  z-index: 19 !important;
}

#loading-bar-spinner.spinner .spinner-icon {}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#overlay {
  overflow-y: scroll;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.9;
  z-index: 9999;
  padding-top: 10px;
}

.uploading-caption {
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: 20px;
  position: absolute;
  z-index: 19 !important;
}

/* 
.download-button {
  display: none;
} */

/* search box starts*/
/* .height {
  height: 100vh;
} */

.login-page {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* margin: 0 5px
; */
}

.ssologin {
  /* margin-right: 5px; */
  margin-bottom: 30px;
}

.register {
  /* margin-left: 10px; */
  text-decoration: none;
  /* color: inherit; */
  /* color: white; */
  background-color: #08019f;
  color: #ffffff;
}

.register:hover {
  text-decoration: none;
  color: #ffffff;
  /* color: #08019f;
  background-color: #ffffff; */
}

.display-form {
  margin: 0 auto !important;
}

/* .search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
  margin-left: 2px;
  align: center;
  margin: 0px;
} */

.search input {
  height: 43px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
  width: 100%;
}

.search input:focus {
  box-shadow: none;
  border: 2px solid blue;
}

.search .fa-search {
  position: absolute;
  top: 12px;
  left: 16px;
  margin-left: 10px;
}

/* .showing-content {
  padding-top: 0.5rem;
} */
/* .search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 50px;
  width: 110px;
  background: blue;
} */
/* search box ends*/

.card-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* cursor: zoom-in; */
  /* position: relative; */
  /* display: inline-block;  */
}

.card-content .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: lightgray;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 6px 6px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 30%;
  font-size: x-small;
  /* margin-right: 60px; */
}

.card-content:hover .tooltiptext {
  visibility: visible;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

/* .cards-container{
margin-right: 0em;
} */

/* .download-excel {
  position: absolute;
  right: 70px;
  top: 150px;
  text-align: center;
} */

/* .download-button {
  /* padding: 30px; */
/* margin-bottom: 0px; */
/* margin-right: 0px; */
/* position: absolute;
  right: 50px;
  top: 150px; */
/* } */

/* .download-excel h5 {
  /* padding: 0%;
  margin: 0%; */
/* font-weight: lighter; */
/* position: absolute;
  right: 0px;
  top: 100px; */
/* font-size: small; */
/* } */

/* .download-button:hover {
  cursor: pointer;
} */

/* .files_list:hover {
  cursor: pointer;
} */
.export-button {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  color: #3c3c3c;
  font-weight: bold;
  cursor: pointer;
}

.export-button:hover {
  color: #0000a0;
  text-decoration: underline;
  -webkit-text-decoration-color: #00d7b9;
  text-decoration-color: #00d7b9;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}

.fileNameConvention {
  font-size: 14px;
  text-align: center;
  margin-bottom: 47px;
  /* margin-left: 220px; */
}

.home-search-text {
  font-size: 16px;
  text-align: center;
  margin-bottom: 47px;
}

/* .when-applicable {
  font-size: 14px;
  text-align: center;
  margin-bottom: 47px;
} */

.container-fluid {
  padding-bottom: 20px;
}

a {
  color: #3c3c3c;
}

.active {
  padding: 5px 5px;
  background-color: #3c3c3c !important;
  color: #ffffff !important;
}

.load-more {
  display: flex;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.here-button {
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  color: #0000a0;
  font-weight: bold;
  cursor: pointer;
}

/* ----------spinner css ----------- */
.lds-dual-ring {
  /* change color here */
  color: #3b51fa;
  display: flex !important;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ----------------end spinner css------------------------------------- */

.exporter {
  margin-right: 25px;
}

.header-a {
  font-weight: bold;
  /* color: #3c3c3c !important; */
}

.header-a:hover {
  color: #0000a0;
  text-decoration: underline;
  -webkit-text-decoration-color: #00d7b9;
  text-decoration-color: #00d7b9;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}

.fileno-text {
  color: #0000a0;
}

a:hover {
  color: #0000a0;
  text-decoration: solid;
  -webkit-text-decoration-color: #00d7b9;
  text-decoration-color: #00d7b9;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}

.cancel-upload:hover {
  cursor: pointer;
}

.upload-icon {
  left: 45%;
  top: 10%;
}

.upload-files {
  /* margin-top: 10em; */
  top: 35%;
  left: 38%;
}

.fileName {
  word-wrap: break-word;
}

@media screen and (max-width: 599px) {
  .upload-icon {
    left: 30%;
  }

  .upload-files {
    left: 2%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .upload-files {
    left: 20%;
  }
}

@media only screen and (min-width: 1001px) and (max-width: 1400px) {
  .upload-files {
    left: 18%;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1800px) {
  .upload-files {
    left: 24%;
  }
}

@media only screen and (min-width: 1801px) and (max-width: 2299px) {
  .upload-files {
    left: 28%;
  }
}

@media only screen and (min-width: 2300px) and (max-width: 4000px) {
  .upload-files {
    left: 32%;
  }
}